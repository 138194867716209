import React from "react"

import Layoutalt from "../components/layoutalt"
import SEO from "../components/seo"
import Ctacontact from "..//components/ctacontact/ctacontact"
import Partners from "..//components/partners/partners"

const ProjetPage = () => (
  <Layoutalt>
    <SEO title="Présentation de notre projet TechYourChance" />

    <section className="container small">
      <h1><span>Le projet TYC</span></h1>
      <p>TechYourChance est une entreprise à impact social qui propose des formations aux métiers du numérique, 100 % gratuites pour les jeunes, les demandeurs d’emploi et les personnes en reconversion.</p>
      <p>En pleine expansion, le secteur du numérique offre un véritable vivier d’emplois. C’est pourquoi notre organisme propose de nombreuses formations innovantes aux métiers émergents du numérique pour permettre aux personnes avec ou sans diplôme d’intégrer rapidement le monde du travail.</p>
      <p>TechYourChance propose une méthode d’apprentissage innovante, immersive, agile et horizontale, privilégiant le pair à pair, le mode projet et le développement de la capacité de collaboration. Il s’agit d’une démarche expérimentale, complémentaire à l’offre de formation secondaire et professionnelle, qui permet de répondre de manière agile à des besoins non couverts aujourd’hui, dans un double objectif d’adaptation et de développement de connaissances.</p>
    </section>

    

    <Ctacontact />

    <Partners />
  </Layoutalt>
)

export default ProjetPage
